import { Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import axios from 'axios';
import { programs } from '@metaplex/js';
import list from "./list.json";

const {
  metadata: { Metadata },
} = programs;

export interface INFT {
  pubkey?: PublicKey;
  mint: PublicKey;
  onchainMetadata: unknown;
  externalMetadata: unknown;
}

async function getTokensByOwner(owner: PublicKey, conn: Connection) {
  const tokens = await conn.getParsedTokenAccountsByOwner(owner, {
    programId: TOKEN_PROGRAM_ID,
  });

  // initial filter - only tokens with 0 decimals & of which 1 is present in the wallet
  return tokens.value
    .filter((t) => {
      const amount = t.account.data.parsed.info.tokenAmount;
      return amount.decimals === 0 && amount.uiAmount === 1;
    })
    .map((t) => {
      return { pubkey: t.pubkey, mint: t.account.data.parsed.info.mint };
    });
}

async function getNFTMetadata(
  mint: string,
  conn: Connection,
  pubkey?: string
): Promise<INFT | undefined> {
  console.log('Pulling metadata for:', mint);
  try {
    // const metadataPDA = await Metadata.getPDA(mint);
    // const onchainMetadata = (await Metadata.load(conn, metadataPDA)).data;
    // const externalMetadata = (await axios.get(onchainMetadata.data.uri)).data;
    const externalMetadata = (await getList(mint))
    return {
      pubkey: pubkey ? new PublicKey(pubkey) : undefined,
      mint: new PublicKey(mint),
      onchainMetadata: externalMetadata,
      externalMetadata: externalMetadata,
    };
  } catch (e) {
    console.log(`failed to pull metadata for token ${mint}`);
  }
}

export async function getList(onchainMetadata: string) {
  let data = list.filter(d => d.mint == onchainMetadata)
  if (data.length > 0) {
    if(data[0]) {
      return data[0].metadata
    }
  }
  return undefined
}

export async function getNFTMetadataForMany(
  tokens: any[],
  conn: Connection
): Promise<INFT[]> {
  const promises: Promise<INFT | undefined>[] = [];


  // if (tokens.length > 10) {
  //   tokens = tokens.slice(0, 10)
  // }
  // console.log(tokens)

  // tokens.forEach((t) => {
  //   console.log("mint:", t.mint, "public", t.pubkey)
  // })

  tokens.forEach((t) => promises.push(getNFTMetadata(t.mint, conn, t.pubkey)));
  const nfts = (await Promise.all(promises)).filter((n) => !!n);
  console.log(nfts)
  console.log(`found ${nfts.length} metadatas`);
  // const nfts = {}
  return nfts as INFT[];
}

export async function getNFTsByOwner(
  owner: PublicKey,
  conn: Connection
): Promise<INFT[]> {
  const tokens = await getTokensByOwner(owner, conn);
  console.log(`found ${tokens.length} tokens`);
  return await getNFTMetadataForMany(tokens, conn);
}