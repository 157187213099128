<template>
  <div class="col-md-10 mx-auto">
    <div class="parent">
      <div class="child">
        <farmer/>
      </div>
    </div>
  </div>
</template>

<script>
import Farmer from "@/views/Farmer";

export default {
  components: {Farmer},
};
</script>

<style>
* {
  font-family: 'Press Start 2P', monospace;
}

input[type='radio']:checked + span {
  @apply text-black;
}

.parent {
  display: flex;
  position: relative;
  height: 100vh;
}

.child {
  margin: auto;
}
</style>
