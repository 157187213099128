import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70af6182"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.nft.onchainMetadata && _ctx.nft.onchainMetadata.name.includes('funkydino.xyz #'))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["m-1 card flex justify-center", { 'card-selected': _ctx.selected }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
      }, [
        _createElementVNode("img", {
          src: _ctx.nft.externalMetadata.properties.files[1].uri,
          alt: _ctx.nft.onchainMetadata.name
        }, null, 8, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}